
import { defineComponent } from "vue";

import { useDataStore } from "@/store/_DataStore";

import gsap from "gsap";

import * as SpotifyHelper from "@/utils/Spotify";

export default defineComponent({
  name: "Match",
  data() {
    return {
      songs: [] as any[],
      voted: 0,
      matchActive: false,
    };
  },
  async created() {
    const store = useDataStore();
    const token = store.getSpotifyAccessToken;
    const res = await SpotifyHelper.getUserTop(token);
    this.songs = res;
  },
  methods: {
    voteSong(vote: any) {
      const current = `song${this.songs.length - 1}`;
      const song = this.$refs[current] as HTMLElement;
      gsap.to(song, {
        x: vote == -1 ? -100 : 100,
        rotateZ: vote == -1 ? -20 : 20,
        onComplete: () => {
          this.songs.pop();
          vote == 1 ? this.voted++ : '';
          console.log(this.voted)
         
          if (this.voted == Math.floor(Math.random() * (8 - 4 + 1) + 4)) {
            this.matchActive = true;
          }
        },
      });
    },
  },
});
