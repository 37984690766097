import { defineStore } from 'pinia'

import { ISpotifyUser } from '@/interfaces/_ICore'

export const useDataStore = defineStore('data', {
  state: () => {
    return {
      spotifyAccessToken: '' as string,
      spotifyUser: {} as ISpotifyUser,
    }
  },
  actions: {

  },
  getters: {
    getSpotifyAccessToken(state): string {
      return state.spotifyAccessToken;
    },
    getSpotifyUser(state): ISpotifyUser {
      return state.spotifyUser
    },
  }
})