import { PageName } from '@/utils/_Constants';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Match from  '../views/Match.vue';
import Results from '../views/Results.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: PageName.HOME,
    component: Home
  },
  {
    path: '/match',
    name: PageName.MATCH,
    component: Match
  },
  {
    path: '/results',
    name: PageName.RESULTS,
    component: Results
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
