
import { defineComponent } from "vue";

import { useDataStore } from "@/store/_DataStore";
import { ISpotifyUser } from "@/interfaces/_ICore";

import * as SpotifyHelper from "@/utils/Spotify";

export default defineComponent({
  name: "Home",
  data() {
    return {
      token: "",
    };
  },
  mounted() {
    this.token = window.location.hash.substr(1).split("&")[0].split("=")[1];

    if (this.token) {
      window.opener.spotifyCallback(this.token);
    }
  },
  methods: {
    spotifyConnect() {
      const store = useDataStore();
      const clientId = process.env.VUE_APP_SPOTIFY_CLIENT_ID;
      const redirectUri = window.location.origin;
      const scope =
        "playlist-modify-public user-read-private user-read-email user-library-read user-top-read user-library-modify user-follow-modify";

      let popup = window.open(
        `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=token&redirect_uri=${redirectUri}&scope=${scope}&show_dialog=true`,
        "Login with Spotify",
        "width=800,height=600"
      );

      (window as any).spotifyCallback = async (payload: any) => {
        popup?.close();

        store.$patch({ spotifyAccessToken: payload });

        const getAccount = await SpotifyHelper.getAccount(payload);
        store.$patch({ spotifyUser: getAccount });

        this.$router.push({
          name: "Match",
        });
      };
    },
  },
});
