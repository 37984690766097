import axios from "axios";

export async function getAccount(token: string) {
  try {
    const response = await axios.get(`https://api.spotify.com/v1/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return false;
    } else {
      console.log("Error", error.message);
    }
  }
}

export async function getUserTop(token: string) {
  const response = await axios.get(
    `https://api.spotify.com/v1/me/top/tracks?time_range=long_term`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data.items;
}

export async function createPlaylist(
  token: string,
  user: any,
  name: string,
  description: string
) {
  const data = {
    name: name,
    description: description,
    public: true,
  };

  const response = await axios.post(
    `https://api.spotify.com/v1/users/${user.id}/playlists`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  return response.data.id;
}

export async function addTracksToPlaylist(
  token: string,
  id: string,
  tracks: any
) {
  const apiCallAmount = Math.ceil(tracks.length / 100);

  const addedTracks = await Promise.all(
    [...new Array(apiCallAmount)].map(async (key, index) => {
      const response = await axios.post(
        `https://api.spotify.com/v1/playlists/${id}/tracks`,
        {
          uris: tracks.splice(0, 100),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    })
  );

  return addedTracks;
}

export async function updatePlaylistTracks(
  token: string,
  id: string,
  tracks: any
) {
  const apiCallAmount = Math.ceil(tracks.length / 100);

  const addedTracks = await Promise.all(
    [...new Array(apiCallAmount)].map(async (key, index) => {
      const response = await axios.put(
        `https://api.spotify.com/v1/playlists/${id}/tracks`,
        {
          uris: tracks.splice(0, 100),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    })
  );

  return addedTracks;
}

export async function getPlaylist(id: string, token: string) {
  const response = await axios.get(
    `https://api.spotify.com/v1/playlists/${id}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return response.data;
}

export async function followPlaylist(id: string, token: string) {
  const data = {
    public: true,
  };

  const response = await axios.put(
    `https://api.spotify.com/v1/playlists/${id}/followers`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
}

export async function followArtist(id: string, token: string) {
  const response = await axios.put(
    `https://api.spotify.com/v1/me/following?type=artist&ids=${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
}

export async function saveAlbum(id: string, token: string) {
  const response = await axios.put(
    `https://api.spotify.com/v1/me/albums?ids=${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
}